var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('div',{staticClass:"card",class:_vm.$style.container},[_c('div',{staticClass:"text-dark font-size-24 mb-4"},[_c('strong',[_vm._v("Reset Password")])]),_c('a-form',{staticClass:"mb-4",attrs:{"form":_vm.form}},[(!_vm.allowPasswordChange)?_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'email',
              {
                rules: [
                  { required: true, message: 'Please input your Email!' },
                ],
              },
            ]),expression:"[\n              'email',\n              {\n                rules: [\n                  { required: true, message: 'Please input your Email!' },\n                ],\n              },\n            ]"}],attrs:{"size":"large","placeholder":"Email Address"}})],1):_vm._e(),(_vm.allowPasswordChange)?_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'password',
              {
                initialValue: '',
                rules: [
                  {
                    required: true,
                    message: 'Please enter your new Password!',
                  },
                  {
                    pattern: /(?=^.{5,}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).*/,
                    message:
                      'Password must contain atleast a digit, an uppercase and lower case letter, 5 characters minimum and a special character',
                  },
                ],
              },
            ]),expression:"[\n              'password',\n              {\n                initialValue: '',\n                rules: [\n                  {\n                    required: true,\n                    message: 'Please enter your new Password!',\n                  },\n                  {\n                    pattern: /(?=^.{5,}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).*/,\n                    message:\n                      'Password must contain atleast a digit, an uppercase and lower case letter, 5 characters minimum and a special character',\n                  },\n                ],\n              },\n            ]"}],attrs:{"has-feedback":"","size":"large","placeholder":"Enter new password","type":"password"}})],1):_vm._e(),(_vm.allowPasswordChange)?_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'confirm_password',
              {
                rules: [
                  {
                    required: true,
                    message: 'Please repeat your password',
                  },
                  {
                    validator: _vm.compareToFirstPassword,
                  },
                ],
              },
            ]),expression:"[\n              'confirm_password',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Please repeat your password',\n                  },\n                  {\n                    validator: compareToFirstPassword,\n                  },\n                ],\n              },\n            ]"}],attrs:{"size":"large","type":"password","placeholder":"Confirm Password","has-feedback":""}})],1):_vm._e(),_c('a-button',{staticClass:"text-center w-100",attrs:{"size":"large","type":"primary"},on:{"click":_vm.handleSubmit}},[_c('strong',[_vm._v("Reset my password")])])],1),_c('router-link',{staticClass:"kit__utils__link font-size-16",attrs:{"to":"/auth/login"}},[_c('i',{staticClass:"fe fe-arrow-left mr-1 align-middle"}),_vm._v(" Go to Sign in ")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }