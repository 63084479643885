<template>
  <div>
    <a-spin :spinning="loading">
      <div class="card" :class="$style.container">
        <div class="text-dark font-size-24 mb-4">
          <strong>Reset Password</strong>
        </div>

        <a-form class="mb-4" :form="form">
          <a-form-item v-if="!allowPasswordChange">
            <a-input
              size="large"
              placeholder="Email Address"
              v-decorator="[
                'email',
                {
                  rules: [
                    { required: true, message: 'Please input your Email!' },
                  ],
                },
              ]"
            />
          </a-form-item>

          <a-form-item v-if="allowPasswordChange">
            <a-input-password
              has-feedback
              size="large"
              placeholder="Enter new password"
              type="password"
              v-decorator="[
                'password',
                {
                  initialValue: '',
                  rules: [
                    {
                      required: true,
                      message: 'Please enter your new Password!',
                    },
                    {
                      pattern: /(?=^.{5,}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).*/,
                      message:
                        'Password must contain atleast a digit, an uppercase and lower case letter, 5 characters minimum and a special character',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>

          <a-form-item v-if="allowPasswordChange">
            <a-input-password
              size="large"
              type="password"
              placeholder="Confirm Password"
              has-feedback
              v-decorator="[
                'confirm_password',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Please repeat your password',
                    },
                    {
                      validator: compareToFirstPassword,
                    },
                  ],
                },
              ]"
            />
          </a-form-item>

          <a-button
            @click="handleSubmit"
            size="large"
            type="primary"
            class="text-center w-100"
          >
            <strong>Reset my password</strong>
          </a-button>
        </a-form>

        <router-link to="/auth/login" class="kit__utils__link font-size-16">
          <i class="fe fe-arrow-left mr-1 align-middle" />
          Go to Sign in
        </router-link>
      </div>
    </a-spin>
  </div>
</template>
<script>
import { mapActions } from "vuex";

import {
  AUTH_CONFIRM_RESET_PASSWORD,
  AUTH_RESET_PASSWORD,
} from "@/store/actions";

export default {
  name: "CuiForgotPassword",
  data() {
    return {
      loading: false,

      form: this.$form.createForm(this),
      allowPasswordChange: false,
    };
  },
  watch: {
    "$route.query.token": {
      handler(token) {
        if (token) {
          this.allowPasswordChange = true;
        } else {
          this.allowPasswordChange = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("auth", {
      getResetPasswordToken: AUTH_RESET_PASSWORD,
      resetPassword: AUTH_CONFIRM_RESET_PASSWORD,
    }),
    compareToFirstPassword(rule, value, callback) {
      if (value && value !== this.form.getFieldValue("password")) {
        callback("Two passwords that you enter is inconsistent!");
      } else {
        callback();
      }
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          try {
            this.resetPasswordHandler(values);
          } catch (e) {
            this.$notification.error({
              message: "An error occurred",
              description: "There was an error with your request!",
            });
          }
        }
      });
    },
    async resetPasswordHandler(values) {
      try {
        this.loading = true;

        if (!this.allowPasswordChange) {
          const { email } = values;
          await this.getResetPasswordToken({ email });

          this.$router.push("/auth/login");
        } else {
          const resetResponse = await this.resetPassword({
            payload: {
              ...values,
              token: this.$route.query.token,
            },
          });

          if (resetResponse && resetResponse.status === "success") {
            this.$router.push("/auth/login").catch(() => {});
          }
        }
      } catch (error) {
        const error_message =
          error.message || "An error occurred, please try again";

        this.$notification.error({
          message: error_message,
          description: "",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" module>
@import "@/components/cleanui/system/Auth/style.module.scss";
</style>
